


















































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';

export default defineComponent({
  name: 'uOttawa1321Lab2Procedure',
  components: {TableInput, STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        procedure: null,
        rows: [{compound: '', molWt: '', amount: '', density: '', mmol: ''}],
        observations: null,
      },
      columns: [
        {label: 'Compound', component: VTextField, prop: 'compound'},
        {label: 'Mol. Wt (g/mol)', component: VTextField, prop: 'molWt'},
        {label: 'Amount', component: VTextField, prop: 'amount'},
        {label: 'Density (g/mL)', component: VTextField, prop: 'density'},
        {label: 'mmol', component: VTextField, prop: 'mmol'},
      ],
    };
  },
});
